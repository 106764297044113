<template>
    <section class="subscription">
        <div>
            <div v-if="!isSubscribed" class="mb-2" v-b-tooltip :title="$t(notEnoughGold ? 'notEnoughGold' : '')">
                <b-button @click="subscribe()" variant="inverse-warning" class="ml-2 text-uppercase" :disabled="notEnoughGold">
                    <i class="mdi mdi-playlist-plus btn-icon-prepend" /> {{ $t('subscribe') }}
                </b-button>
            </div>   
            <div v-else class="mb-2">
                <span>Subscribed</span>
            </div>
        </div>
    </section> 
</template>

<script>

export default {
    name: 'subscription-button',
    props: ["userLinkId"],
    data() {
        return {
            isSubscribed: false,
            costForSubscribe: 100
        }
    },
    computed: {
        notEnoughGold() {
            return this.User.gold < this.costForSubscribe;
        }
    },
    created () {
        this.$bobwinHub.$on('subscribeCallback', this.subscribeCallback);
        this.$bobwinHub.$on('checkSubscribeCallback', this.checkSubscribeCallback);
        this.init();
    },
    beforeDestroy() {
        this.$bobwinHub.$off('subscribeCallback', this.subscribeCallback);
        this.$bobwinHub.$off('checkSubscribeCallback', this.checkSubscribeCallback);
    },
    methods: {
        init() {
            this.CallHub({task: 'CheckSubscribed', callback: 'checkSubscribeCallback', data: this.userLinkId});
        },
        checkSubscribeCallback(response) {
            const receivedData = JSON.parse(response.data);
            this.isSubscribed = receivedData.isSubscribed;
        },
        subscribe() {
            this.ShowConfirmMessage(this.$t("confirm"), this.$t("subscribeConfirmMessage"), this.processSubcribe);
        },
        processSubcribe() {
            this.CallHub({task: 'Subscribe', callback: 'subscribeCallback', data: this.userLinkId});
        },
        subscribeCallback(response) {
            if (response.result == "error") {
                this.ShowErrorMessage(response.message);
            } else if (response.result == "success") {
                this.ShowSuccessMessage("Subscribed");
                this.isSubscribed = true;
                const user = JSON.parse(response.userData);
                this.$store.dispatch('setUser', user);
            }
        }
    }
}
</script>
