<script>
import {
  Line
} from 'vue-chartjs'

export default {
  extends: Line,
  data () {
    return {
    }
  },
  props: {
    doRender: {
      type: Number,
      default: () => 0
    },
    datacollection: {
      type: Object,
      default: () => { 
        return {
          labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6'],
          datasets: [{
            label: '# of Votes',
            data: [10, 19, 3, 5, 2, 3],
            borderColor: [
              'rgba(255,99,132,1)'
            ],
            borderWidth: 1,
            fill: false
          }]};
        }
    },
    options: {
      type: Object,
      default: () => { 
        return {
          default: {
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true
                },
                gridLines: {
                  display: true
                }
              }],
              xAxes: [{
                ticks: {
                  beginAtZero: true
                },
                gridLines: {
                  display: true
                }
              }]
            },
            legend: {
              display: true
            },
            elements: {
              point: {
                radius: 0
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          height: 300,
        }
      }
    }
  },
  watch: {
    datacollection: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.renderChart(this.datacollection, this.options)
      }
    }
  },
  mounted: function () {
    this.renderChart(this.datacollection, this.options)
  }
}
</script>
