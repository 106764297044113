<template>
  <section class="userDetail">
    <b-modal id="userDetail-modal" class="userDetail-modal" :title="title" size="xl" button-size="lg" ok-only @click="handleOk">
      <div v-if="loading">
        <div class="pixel-loader"></div>
      </div>
      <div v-else>
        <div class="p-3" v-if="hasError">
          <h6 class="text-danger p-2">! {{ errorMessage }}</h6>
        </div>
        <div class="row">
            <subscription-button :userLinkId="userLinkId"/>
        </div>
        <div class="row">
          <div v-for="statistic in statistics" :key="statistic.id" class="col-xl-3 col-sm-6 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-9">
                    <div class="d-flex align-items-center align-self-start">
                      <h3 class="mb-0">{{ statistic.title }}</h3>
                      <p class="ml-2 mb-0 font-weight-medium" :class="statistic.title >= 0 ? 'text-success' : 'text-danger'">{{ statistic.subtitle }}</p>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="icon" :class="statistic.title < 0 ? 'icon-box-danger' : 'icon-box-success'">
                      <span class="mdi icon-item" :class="statistic.title < 0 ? 'mdi-arrow-bottom-left' : 'mdi-arrow-top-right'"></span>
                    </div>
                  </div>
                </div>
                <h6 class="text-muted font-weight-normal">{{statistic.label}}</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div  class="card-title">
                  <h4>{{$t('dailyReport')}}</h4>
                </div>
                <lineChart :datacollection="datacollection" :options="options" />
              </div>
            </div>
          </div>
          <div class="col-md-6 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="card-title">
                  <h4>{{$t('weeklyReport')}}</h4>
                </div>
                <barChart :datacollection="datacollection2" :options="options2" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <bet-history :bets="sortedBets"/>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import lineChart from '@/components/charts/chartjs/lineChart.vue'
import barChart from '@/components/charts/chartjs/barChart.vue'
import betHistory from '@/pages/share/bet-history';
import subscriptionButton from '@/pages/share/subscription-button.vue';

export default {
  data(){
    return {
      comment: '',
      hasError: '',
      errorMessage: '',
      title: '',
      profit: 0,
      loading: true,
      statistics: [],
      bets: [],
      datacollection: {},
      options: {},
      datacollection2: {},
      options2: {},
      userLinkId: '',
      isSubscribed: false,
      betProfitRate: 0
    }
  },
  components: {
    lineChart,
    barChart,
    betHistory,
    subscriptionButton
  },
  computed: {
    sortedBets() {
      if (Array.isArray(this.bets)) {
        let copy = [...this.bets];
        copy.sort(function (a,b) {
          return a.eventDate > b.eventDate ? -1 : 1;
        });

        return copy;
      }
      return [];
    },
  },
  created () {
      this.$root.$on('openUserDetailDialog', this.openUserDetailDialog);
      this.$bobwinHub.$on('statisticsReceived', this.statisticsReceived);
  },
  beforeDestroy() {
    this.$root.$off("openUserDetailDialog", this.openUserDetailDialog);
    this.$bobwinHub.$off('statisticsReceived', this.statisticsReceived);
  },
  methods: {
    handleOk(bvModalEvt){
      bvModalEvt.preventDefault();
    },
    openUserDetailDialog (data) {
      this.title = data.userName;
      this.$bvModal.show('userDetail-modal');
      this.loading = true;
      this.userLinkId = data.linkId;
      this.CallHub({task: 'GetStatistics', callback: 'statisticsReceived', data: data.linkId});
    },
    statisticsReceived (response) {
      const receivedData = JSON.parse(response.data);
      this.bets = receivedData.bets;
      this.isSubscribed = receivedData.isSubscribed;
      this.betProfitRate = receivedData.betProfitRate;
      this.init();
    },
    getWeeklyData (dailyData) {
      const weeklyData = dailyData.reduce((acc, curr) => {
        const week = this.getWeek(curr.date);
        const year = (new Date(curr.date)).getUTCFullYear();
        if (!acc[week]) {
          acc[week] = { week, value: 0, count: 0, year: year };
        }
        const curVal = Number(curr.value);
        acc[week].value += curVal;
        acc[week].count++;
        return acc;
      }, {});

      const result = [];
      // Calculate average value for each week
      Object.values(weeklyData).forEach((week) => {
        delete week.count;
        result.push(week);
      });

      return result;//window.console.log(weeklyData);
    },
    getWeek(dateString) {
        const date = new Date(dateString);
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth();
        const day = date.getUTCDate();
        const d = new Date(Date.UTC(year, month, day));
        const weekStart = new Date(d.getFullYear(), 0, 1);
        const weekNo = Math.ceil(((d.getTime() - weekStart.getTime()) / 86400000 + weekStart.getDay() + 1) / 7);
        return weekNo;
    },
    getMonthAndWeek(week, year) {
      const januaryFirst = new Date(year, 0, 1);
      const weekStart = new Date(januaryFirst.getTime() + (week - 1) * 7 * 86400000);
      const month = weekStart.getMonth() + 1;
      const weekOfMonth = Math.ceil((weekStart.getDate() + 6 - weekStart.getDay()) / 7);
      return { month, week: weekOfMonth };
    },
    init () {
      if (Array.isArray(this.bets)) {
        const wonBets = this.bets.filter(bet => bet.status === 1);
        const lostBets = this.bets.filter(bet => bet.status === 2);

        let roiTotal = 0;
        let won = 0;
        wonBets.forEach((bet) => {
          won += ((bet.odds * bet.amount) - bet.amount);
          roiTotal += bet.amount;
        });
        let lost = 0;
        lostBets.forEach((bet) => {
          lost -= bet.amount;
          roiTotal += bet.amount;
        });
        this.profit = won + lost;
        this.dictionary = new Object();
        this.bets.forEach((bet) => {
          this.totalSpent += bet.amount;
          const date = (new Date(bet.eventDate)).toLocaleDateString("ko-KR");
          const prev = this.dictionary[date] == null ? 0 : this.dictionary[date];
          if (bet.status === 1) {
            this.dictionary[date] = prev + ((bet.odds * bet.amount) - bet.amount);
          } else if (bet.status === 2) {
            this.dictionary[date] = prev - bet.amount;
          }
        });
        this.roi = roiTotal === 0 ? 'N/A' : (this.profit / roiTotal * 100).toFixed(2);
        this.winRate = (wonBets.length + lostBets.length) === 0 ? 'N/A' : (wonBets.length / (wonBets.length + lostBets.length) * 100).toFixed(0);

        let copy = [...this.bets];
        copy.sort(function (a,b) {
          return a.betDate > b.betDate ? 1 : -1;
        });

        const numberOfWon = wonBets.length;
        const numberOfLost = lostBets.length;
        const numberOfOpen = this.bets.length - numberOfWon - numberOfLost;
        this.transactionPercetages = [(numberOfOpen / this.bets.length) * 100, 
          (numberOfWon / this.bets.length) * 100, 
          (numberOfLost / this.bets.length) * 100];

        this.statistics = [ 
          { id: 0, label: this.$t('profitLoss'), title: this.profit.toFixed(2), subtitle:'Playmoney'}, 
          { id: 1, label: this.$t('ProfitRateByDeposit'), title: this.betProfitRate.toFixed(2), subtitle:'%'}, 
          { id: 2, label: this.$t('roi'), title: this.GetFloorValue(this.roi, 2), subtitle:'%'}, 
          { id: 3, label: (this.$t('status.won') + ' / ' + this.$t('status.lost') + ' (' + this.$t('winrate') + ')'), title: (wonBets.length + '/' + lostBets.length + ' (' + this.GetFloorValue(this.winRate, 2) + ' %)'), subtitle:''}, 
        ];

        const keys = Object.keys(this.dictionary).sort(function (a,b) {
          return new Date(a) > new Date(b) ? 1 : -1;
        });
        const values = [], dailyData = [], dailyKeys = [];
        for (var i=0; i<keys.length; i++) { 
          values[i] = this.dictionary[keys[i]].toFixed(2);
          dailyData.push({ date: keys[i], value: values[i] });
          dailyKeys.push(this.GetFormattedDate(new Date(keys[i])));
        } 
        const weekKeys = [], weekValues = [];
        const weeklyData = this.getWeeklyData(dailyData);
        weeklyData.forEach((data) => {
          weekKeys.push('week ' + data.week);
          weekValues.push(data.value)
        });

        this.datacollection = this.getDataCollection(dailyKeys, values);
        this.options = this.getOptions();
        this.datacollection2 = this.getDataCollection(weekKeys, weekValues);
        this.options2 = this.getOptions();

        this.loading = false;
      }
    },
    getDataCollection(keys, values) {
      const borderColors = [];
      const color = 'rgba(83,245,89,1)';
      for(let i = 0; i < values.length; ++i) {
        borderColors.push(color);
      }
      return {
        labels: keys,
        datasets: [{
          label: 'Profit',
          data: values,
          borderColor: borderColors,
          borderWidth: 2,
          fill: false
        }]};
    },
    getOptions() {
      return {
        scales: {
          yAxes: [{
            ticks: { beginAtZero: true },
            gridLines: { display: true }
          }],
          xAxes: [{
            ticks: { beginAtZero: true },
            gridLines: { display: true }
          }]
        },
        legend: { display: true },
        elements: { point: { radius: 0 } },
        responsive: true,
        maintainAspectRatio: false,
      };
    }
  }
}
</script>
<style scoped>
  .radio-currency {
    width: 100%;
  }
  .form-group {
    margin-bottom: 0.2rem;
  }
  .overflow-height {
    height: 500px;
  }
  .userDetail-modal {
    width: 1000px !important;
  }
</style>