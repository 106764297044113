<template>
    <div class="col-12 grid-margin">
        <div class="card">
            <div class="card-body">
            <div>
                <h4 class="card-title">{{$t('bethistory')}}</h4>
            </div>
                <perfect-scrollbar>
                <div class="overflow-height">
                    <div class="table-responsive ">
                    <table class="table">
                        <thead>
                        <tr>
                            <th> {{$t('betStatus')}} </th>
                            <th> {{$t('profitLoss')}} </th>
                            <th> {{$t('stake') + " (" + $t('odds') + ")"}}  </th>
                            <th> {{$t('eventDate')}} </th>
                            <th> {{$t('sport')}} </th>
                            <th> {{$t('eventTitle')}} </th>
                            <th> {{$t('gameTitle')}} </th>
                            <th> {{$t('optionTitle')}} </th>
                        </tr>
                        </thead>
                        <tbody class="sortable">
                            <tr v-for="bet in bets" :key="bet.id">
                            <td>
                                <div v-if="bet.status === 1" class="badge badge-outline-success">{{$t('status.won')}}</div>
                                <div v-else-if="bet.status === 2" class="badge badge-outline-danger">{{$t('status.lost')}}</div>
                                <div v-else-if="bet.status === 6" class="badge badge-outline-warning" v-b-tooltip :title="$t('canceledGame')">{{$t('status.canceled')}}</div>
                                <div v-else class="badge badge-outline-warning">{{$t('status.inProgress')}}</div>
                            </td>
                            <td> {{bet.status === 1 ? (bet.amount * bet.odds).toFixed(2) : (bet.status === 2 ? bet.amount : 'N/A')}} </td>
                            <td> {{bet.amount}} (<odds-value :odds="bet.odds" />)</td>
                            <td>
                                <span class="pl-2">{{GetFormattedDatetime(bet.eventDate)}}</span>
                            </td>
                            <td> {{bet.eventCategory}} </td>
                            <td> {{bet.eventTitle}} </td>
                            <td> {{bet.gameTitle}} </td>
                            <td> {{bet.gameOptionTitle}} </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</template>

<script>
import oddsValue from '@/pages/share/odds-value';

export default {
  name: 'bet-history',
  components: {
    oddsValue
  },
  data() {
    return {
    }
  },
  props: ["bets"],
}
</script>

<style lang="scss" scoped>
.overflow-height {
  height: 500px;
}
</style>